import * as Yup from 'yup';

const city = Yup.string()
    .min(2, 'City must be at least 2 characters long.')
    .max(30, 'City must be no longer than 30 characters.');
const cityRequired = city.required('This is a required field.');
const state = Yup.string()
    .min(2, 'State must be at least 2 characters long.')
    .max(30, 'State must be no longer than 30 characters.');
const stateRequired = city.required('This is a required field.');
const hearAbout = Yup.string()
    .min(2, 'Hear about must be at least 2 characters long.')
    .max(30, 'Hear about must be no longer than 30 characters.');
const hearAboutRequired = hearAbout.required('This is a required field.');

const otherSource = Yup.string();
const otherSourceRequired = otherSource.required('This is a required field.');

const typeOfBusiness = Yup.string()
    .min(2, 'Hear about must be at least 2 characters long.')
    .max(30, 'Hear about must be no longer than 30 characters.');
const typeOfBusinessRequired = typeOfBusiness.required('This is a required field.');
const firstName = Yup.string()
    .min(2, 'First Name must be at least 2 characters long.')
    .max(30, 'First Name must be no longer than 30 characters.');
const firstNameRequired = firstName.required('This is a required field.');
const lastName = Yup.string()
    .min(2, 'Last Name must be at least 2 characters long.')
    .max(30, 'Last Name must be no longer than 30 characters.');
const lastNameRequired = lastName.required('This is a required field.');
const company = Yup.string()
    .min(2, 'Company must be at least 2 characters long.')
    .max(30, 'Company must be no longer than 30 characters.');
const companyRequired = company.required('This is a required field.');
const companyName = Yup.string()
    .min(2, 'Company name must be at least 2 characters long.')
    .max(30, 'Company name must be no longer than 30 characters.');
const companyNameRequired = companyName.required('This is a required field.');

const instInterestedIn = Yup.string();
const instInterestedInRequired = instInterestedIn.required('This is a required field.');

const progInterestedIn = Yup.string();
const progInterestedInRequired = progInterestedIn.required('This is a required field.');

const instTypInterestedIn = Yup.string();
const instTypInterestedInRequired = instTypInterestedIn.required('This is a required field.');

const installationInterested = Yup.string();
const installationInterestedRequired = installationInterested.required('This is a required field');

const smallMediumBusinessInterested = Yup.string();
const smallMediumBusinessInterestedRequired = smallMediumBusinessInterested.required('This is a required field');

const geographicalLocations = Yup.string();
const geographicalLocationsRequired = geographicalLocations.required('This is a required field.');

const howSoonDoYouNeedTechnicians = Yup.string();
const howSoonDoYouNeedTechniciansRequired = howSoonDoYouNeedTechnicians.required('This is a required field.');

const estimatedMonthlyWorkOrderVolume = Yup.string();
const estimatedMonthlyWorkOrderVolumeRequired = estimatedMonthlyWorkOrderVolume.required('This is a required field.');

const whatCanWeHelpYouWith = Yup.string();
const whatCanWeHelpYouWithRequired = whatCanWeHelpYouWith.required('This is a required field.');

const phone = Yup.string()
    .matches(
        /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/,
        'Phone must be valid.'
    )
    .max(30, 'Phone must be no longer than 30 characters');
const usPhone = Yup.string()
    .transform((value) => {
        const newVal = value !== null ? value.replace(/\D/g, '').toString() : undefined;
        return newVal;
    })
    .required('Phone is required')
    .matches(/\d{10}/, 'Phone must be valid');
const phoneRequired = phone.required('This is a required field.');
const email = Yup.string().email('Email is not valid.');
const emailRequired = email.required('This is a required field.');

const currentBusinessOwner = Yup.string();
const currentBusinessOwnerRequired = currentBusinessOwner.required('This is a required field');

const currentTechnician = Yup.string();
const currentTechnicianRequired = currentTechnician.required('This is a required field');

const usZip5Optional4 = Yup.string().matches(
    /^[0-9]{5}(?:-[0-9]{4})?$/,
    'ZIP Code must be 5 or 9 digits.'
);
const usZip5Optional4Required = usZip5Optional4.required('This is a required field');
const primaryIndustry = Yup.string().required('You must select an option.');
const howDidYouHear = Yup.string().required('You must select an option.');
const partnerFields = {
    firstName,
    firstNameRequired,
    lastName,
    lastNameRequired,
    company,
    companyRequired,
    city,
    cityRequired,
    state,
    stateRequired,
    phone,
    phoneRequired,
    email,
    emailRequired,
    usZip5Optional4,
    usZip5Optional4Required,
    primaryIndustry,
    howDidYouHear,
    usPhone,
    currentBusinessOwnerRequired,
    currentTechnicianRequired,
    companyNameRequired,
    companyName,
    hearAbout,
    hearAboutRequired,
    otherSource,
    otherSourceRequired,
    typeOfBusiness,
    typeOfBusinessRequired,
    progInterestedIn,
    progInterestedInRequired,
    installationInterested,
    installationInterestedRequired,
    smallMediumBusinessInterested,
    smallMediumBusinessInterestedRequired,
    instTypInterestedInRequired,
    instInterestedInRequired,
    geographicalLocationsRequired,
    howSoonDoYouNeedTechniciansRequired,
    estimatedMonthlyWorkOrderVolumeRequired,
    whatCanWeHelpYouWithRequired,
};
export default partnerFields;
