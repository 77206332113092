import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useDebouncedCallback } from 'use-debounce';
import Sticky from 'react-stickynode';
import PVBlockSearchPlaceHolder from './Header/PVBlockSearchPlaceHolder';
import PVHeaderLogin from './Header/PVHeaderLogin';
import PVSearchBar from './Header/PVSearchBar';
import PVMenuBar from './Header/PVMenuBar';
import PVFooter from './Footer/PVFooter';
import PVPageAlerts from './PVPageAlerts';
import PVBanner from './Header/PVBanner';
import PVLogo from './Header/PVLogo';
import { selectSiteId } from '../../../redux/userProfile/selectors';
import PVBrowserModal from '../Content/Modals/PVBrowserModal';
import { SiteConfig } from '../../../helpers/initSite';
import PVLoadingModalBlockSite from '../Content/Modals/PVLoadingModalBlockSite';
import PVStoppedCustomerModal from '../Content/Modals/PVStoppedCustomerModal';

const PVSiteWrapper = (props) => {
    const { displayFooter, children, siteId, displayBanner, bannerText } = props;
    const isMounted = useRef(null);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    let title = "";
    let metaDescription = "";

    //Keep the logic for SEO metadata in sync in the following places:
    //  MiddleTier/BusinessServiceLayer/WebStore/SEO.cs - First pass of Google indexing (html head)
    //  PV/React/components/Shared/SiteWrapper/PVSiteWrapper.js - Second pass of Google indexing (rendered JavaScript/React helmet)
    //  PV/React/components/PVWebstore/PVShopWrapper.js - Second pass of Google indexing - product category/subcatgory
    switch (window.location.pathname.toLowerCase()) {
        //Shop
            //The Shop menu is handled in PV/React/components/PVWebstore/PVShopWrapper.js
        //Partner with PV
        case "/partnerwithpv/authorizedretailerprogram/becomeanauthorizedretailer":
            title = "Become an Authorized Retailer | PerfectVision";
            metaDescription = "Become an authorized retailer and sell Internet, TV Services, Mobility and Security for the nation�s leading providers.";
            break;
        case "/partnerwithpv/authorizedretailerprogram/directv":
            title = "Become a DIRECTV Authorized Dealer | PerfectVision";
            metaDescription = "Become a DIRECTV Authorized Dealer and sell DIRECTV to homes and businesses nationwide. Join the PerfectVision Retailer Program and increase your revenue.";
            break;
        case "/partnerwithpv/authorizedretailerprogram/spectrum":
            title = "Become a Spectrum Authorized Retailer | PerfectVision";
            metaDescription = "Become a Spectrum Authorized Retailer and sell Spectrum Internet and Mobility products. Join the PerfectVision Retailer Program and increase your revenue.";
            break;
        case "/partnerwithpv/authorizedretailerprogram/t-mobile":
            title = "Become a T-Mobile Authorized Retailer | PerfectVision";
            metaDescription = "Become a T-Mobile Authorized Retailer and sell T-Mobile Wireless and Internet products. Join the PerfectVision Retailer Program and increase your revenue.";
            break;
        case "/partnerwithpv/authorizedretailerprogram/viasat":
            title = "Become a Viasat Authorized Retailer | PerfectVision";
            metaDescription = "Become a Viasat Authorized Retailer and join one of the top retailer programs in the satellite internet industry. Join the PerfectVision Retailer Program and increase your revenue.";
            break;
        case "/partnerwithpv/authorizedretailerprogram/frontier":
            title = "Become a Froniter Authorized Agent | PerfectVision";
            metaDescription = "Become a Froniter Authorized Agent and offer your customers the highest speeds in footprint. Join the PerfectVision Retailer Program and increase your revenue.";
            break;
        case "/partnerwithpv/authorizedretailerprogram/kineticbywindstream":
            title = "Become a Kinetic Authorized Agent | PerfectVision";
            metaDescription = "Become a Kinetic Authorized Agent and offer your customers the highest speeds in footprint. Join the PerfectVision Retailer Program and increase your revenue.";
            break;
        case "/partnerwithpv/authorizedretailerprogram/kinetic":
            title = "Become a Kinetic Authorized Agent | PerfectVision";
            metaDescription = "Become a Kinetic Authorized Agent and offer your customers the highest speeds in footprint. Join the PerfectVision Retailer Program and increase your revenue.";
            break;
        case "/partnerwithpv/authorizedretailerprogram/earthlink":
            title = "Become an EarthLink Authorized Retailer | PerfectVision";
            metaDescription = "Become an Earthlink Authorized Retailer and offer your customers the highest speeds in footprint. Join the PerfectVision Retailer Program and increase your revenue.";
            break;
        case "/partnerwithpv/authorizedretailerprogram/attbusinessalliance":
            title = "Sell AT&T Business Alliance | PerfectVision";
            metaDescription = "Partner with PerfectVision to sell AT&T Business Alliance. Join the PerfectVision Retailer Program and increase your revenue.";
            break;
        case "/partnerwithpv/authorizedretailerprogram/adtsafehaven":
            title = "Sell ADT Home Security | PerfectVision";
            metaDescription = "Partner with PerfectVision to offer your customers ADT security with SafeHaven an Authorized Dealer."
            break;
        case "/partnerwithpv/authorizedretailerprogram/brinkshome":
            title = "Become a BrinksHome Authorized Partner | PerfectVision";
            metaDescription = "Become a BrinksHome Authorized Partner and offer your customers monitored security and home automation. Join the PerfectVision Retailer Program and increase your revenue.";
            break;
        case "/partnerwithpv/telecommunicationsinfrastructureproducts":
            title = "Telecommunications Infrastructure Products | PerfectVision";
            metaDescription = "PerfectVision offers 10,000 wireless communications products for site support, broadband networks, and all areas of telecommunications infrastructure. Explore our product options now.";
            break;
        case "/partner/becomeacustomer":
            title = "Become a Customer | PerfectVision";
            metaDescription = "PerfectVision - Become a customer";
            break;
        //Resources
        case "/resources/digitaldatabase":
            title = "Digital Database | PerfectVision";
            metaDescription = "PerfectVision - Digital Database";
            break;
        case "/resources/catalogs":
            title = "Catalogs | PerfectVision";
            metaDescription = "PerfectVision - Catalogs";
            break;
        case "/resources/products/ourbrands/ourbrands":
            title = "Our Brands | PerfectVision";
            metaDescription = "PerfectVision - Our Brands";
            break;
        case "/resources/productresources/resources":
            title = "Product Resources | PerfectVision";
            metaDescription = "Explore these PerfectVision resources: Small Cell & 5G Solutions, Distributed Antenna System DAS, Reinforcement Kits, Fiber, Coaxial, Category, Networking, Cable Accessories & Solutions, Internet Service Providers, Safety Solutions, Mounting Solutions, Satellite Installation, and Integration Services.";
            break;
        //Resources - VZWSmart
        case "/resources/products/ourbrands/vzwsmart":
            title = "VZWSmart | PerfectVision";
            metaDescription = "PerfectVision - VZWSmart";
            break;
        case "/resources/products/ourbrands/vzwsmart/monopoles":
            title = "VZWSmart Monopole Solutions | PerfectVision";
            metaDescription = "PerfectVision - VZWSmart Verizon Wireless Monopole Solutions";
            break;
        case "/resources/products/ourbrands/vzwsmart/selfsupport":
            title = "VZWSmart Self-Support Solutions | PerfectVision";
            metaDescription = "PerfectVision - VZWSmart Verizon Wireless Self-Support Solutions";
            break;
        case "/resources/products/ourbrands/vzwsmart/hardwarekits":
            title = "VZWSmart Hardware Kits | PerfectVision";
            metaDescription = "PerfectVision - VZWSmart Verizon Wireless Hardware Kits";
            break;
        //Resources - ClimbMaxx
        case "/resources/products/ourbrands/ClimbMaxx":
            title = "ClimbMaxx | PerfectVision";
            metaDescription = "PerfectVision - ClimbMaxx";
            break;
        case "/resources/products/ourbrands/climbmaxx/rungrailapplications":
            title = "ClimbMaxx Rung/Rail Applications | PerfectVision";
            metaDescription = "PerfectVision - ClimbMaxx Rung/Rail Applications";
            break;
        case "/resources/products/ourbrands/climbmaxx/rungrailkits":
            title = "ClimbMaxx Rung/Rail Application Kits | PerfectVision";
            metaDescription = "PerfectVision - ClimbMaxx Rung/Rail Application Kits";
            break;
        case "/resources/products/ourbrands/climbmaxx/poleapplications":
            title = "ClimbMaxx Pole Applications | PerfectVision";
            metaDescription = "PerfectVision - ClimbMaxx Pole Applications";
            break;
        case "/resources/products/ourbrands/climbmaxx/polekits":
            title = "ClimbMaxx Pole Application Kits | PerfectVision";
            metaDescription = "PerfectVision - ClimbMaxx Pole Application Kits";
            break;
        case "/resources/products/ourbrands/climbmaxx/legmountedapplications":
            title = "ClimbMaxx Leg Mounted Applications | PerfectVision";
            metaDescription = "PerfectVision - ClimbMaxx Leg Mounted Applications";
            break;
        case "/resources/products/ourbrands/climbmaxx/legmountedkits":
            title = "ClimbMaxx ClimbMaxx Leg Mounted Application Kits | PerfectVision";
            metaDescription = "PerfectVision - ClimbMaxx Leg Mounted Application Kits";
            break;
        case "/resources/products/ourbrands/climbmaxx/wireroperoutingandaccessories":
            title = "ClimbMaxx Wire Rope Routing And Accessories | PerfectVision";
            metaDescription = "PerfectVision - ClimbMaxx Wire Rope Routing And Accessories";
            break;
        case "/resources/products/ourbrands/climbmaxx/standards":
            title = "ClimbMaxx Standards & Best Practices | PerfectVision";
            metaDescription = "PerfectVision - ClimbMaxx Standards & Best Practices";
            break;
        //Engage
        case "/engage/newsletter":
            title = "Newsletter | PerfectVision";
            metaDescription = "PerfectVision - Newsletter";
            break;        
        case "/engage/whatsnew":
            title = "What's New | PerfectVision";
            metaDescription = "PerfectVision - What's New";
            break;
        //Company        
        case "/company/aboutus":
            title = "About Us | PerfectVision";
            metaDescription = "PerfectVision - About Us";
            break;
        case "/company/findrep":
            title = "Find Representative | PerfectVision";
            metaDescription = "PerfectVision - Find a Representative";
            break;
        case "/company/contactus":
            title = "Contact Us | PerfectVision";
            metaDescription = "PerfectVision - Contact Us";
            break;
        case "/company/facilities":
            title = "Facilities | PerfectVision";
            metaDescription = "PerfectVision - Facilities";
            break;
        case "/company/ourpartners":
            title = "Partners | PerfectVision";
            metaDescription = "PerfectVision - Partners";
            break;
        case "/company/ourvendors":
            title = "Vendors | PerfectVision";
            metaDescription = "PerfectVision - Vendors";
            break;
        case "/company/careers":
            title = "Careers | PerfectVision";
            metaDescription = "PerfectVision - Careers";
            break;
        //Industry pages
        case "/components/shared/content/pvacquistionservices":
            title = "Acquisition Services | PerfectVision";
            metaDescription = "Over 5,000 retailers partner with PerfectVision to earn revenue through the sale of high speed internet, TV services, wireless mobility, and monitored security for the nation�s leading providers for events, door to door, call center, and retail.";
            break
        case "/installationsolutions":
            title = "Installation Solutions | PerfectVision";
            metaDescription = "PerfectVision maintains a network of certified technicians that provide professional installation and service work nationwide including scalable work force, installation service, quality control, service calls, and virtual quality auditing.";
            break;
        case "/components/shared/content/pvinstallationservices":
            title = "Installation Services | PerfectVision";
            metaDescription = "PerfectVision maintains a network of certified technicians that provide professional installation and service work nationwide including scalable work force, installation service, quality control, service calls, and virtual quality auditing.";
            break;
        case "/partnerwithpv/telecommunicationsinfrastructureproducts":
            title = "Product Solutions | PerfectVision";
            metaDescription = "PerfectVision promotes safety, quality, sustainability, and efficiency as a manufacturer and distributor of over 10,000 products supporting wireless deployments, fiber deployments, internet service providers, and wire-line product.";
            break;
        case "/components/shared/content/pvproductsolutions":
            title = "Product Solutions | PerfectVision";
            metaDescription = "PerfectVision promotes safety, quality, sustainability, and efficiency as a manufacturer and distributor of over 10,000 products supporting wireless deployments, fiber deployments, internet service providers, and wire-line product.";
            break;
        //Default
        default:
            title = "PerfectVision";
            //metaDescription = "PerfectVision is an industry leading distributor of satellite, broadband, and wireless products. We provide top quality products, comprehensive dealer support, and unmatched customer service.";
            metaDescription = "PerfectVision is a global leader in Infrastructure Product Solutions as a manufacturer and distributer, Installation Services, Subscriber Acquisition Services and Supply Chain Logistics.";
            break;
    }

    const [handleResize] = useDebouncedCallback(() => {
        setScreenWidth(window.innerWidth);
    }, 600);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return (_) => {
            window.removeEventListener('resize', handleResize);
        };
    });
    return (
        <div className="wrapper">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={metaDescription} data-source="PVSiteWrapper.js"/>
            </Helmet>
            <PVBrowserModal />
            <PVLoadingModalBlockSite />
            <PVStoppedCustomerModal />
            {/*<PVBlockSearchPlaceHolder />*/}
            <header className="site-header header-opt-1">
                <PVHeaderLogin showOrderHistory={siteId !== 13} />
                <div className="hidden-xs hidden-sm hidden-md">
                    {screenWidth > 1199 ? <PVSearchBar showCart={siteId !== 13} /> : ''}
                </div>
                <div className="header-content hidden-sm hidden-md hidden-lg">
                    <PVLogo />
                </div>
                <div id="stickyMenu">
                    <Sticky innerZ={101} activeClass="is-sticky">
                        <div className="hidden-lg">
                            <PVSearchBar showCart={siteId !== 13} />
                        </div>
                        <div className="hidden-xs hidden-sm hidden-md">
                            {screenWidth > 1199 ? <PVMenuBar /> : ''}
                        </div>
                    </Sticky>
                </div>

                <PVBanner
                    heading={bannerText}
                    bannerUrl="/WebSupport/PV_React/WebStore/category-banners/category-generic-bnr.jpg"
                    renderBanner={displayBanner}
                />
                <PVPageAlerts />
            </header>
            {SiteConfig.ShowSystemMessage && (
                <div className="container alert alert-danger">{SiteConfig.SystemMessage}</div>
            )}
            {!SiteConfig.HideContentForSystemMessage && (
                <main className="site-main">{children}</main>
            )}
            <footer className="site-footer footer-opt-2">
                <PVFooter displayFullFooter={displayFooter} />
            </footer>
        </div>
    );
};

PVSiteWrapper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    displayFooter: PropTypes.bool,
    siteId: PropTypes.number.isRequired,
    displayBanner: PropTypes.bool,
    bannerText: PropTypes.string,
};

PVSiteWrapper.defaultProps = {
    children: <span />,
    displayFooter: true,
    displayBanner: false,
    bannerText: '',
};

const mapStateToProps = (state) => ({
    siteId: selectSiteId(state),
});

export default connect(mapStateToProps)(PVSiteWrapper);
